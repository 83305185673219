import { ILinks } from '@/common/interfaces/index.interface';

const links: ILinks[] = [
  {
    locizeIdentifier: `info`,
    openPreferences: true,
  },
  {
    href: `/datenschutz/`,
    locizeIdentifier: `privacy`,
    openPreferences: false,
  },
  {
    href: `/impressum`,
    locizeIdentifier: `impressum`,
    openPreferences: false,
  },
];

export default links;
