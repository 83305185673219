import { ICookie } from '@/common/interfaces/index.interface';

const namespace = `cookie-notice-list`;

export const essentialCookiesCategoryName = `necessary`;

const cookies: ICookie[] = [
  {
    active: true,
    cookies: [
      {
        active: true,
        cookieNames: `ccn-interacted`,
        duration: `${namespace}.durations.oneYear`,
        explanation: ``,
        internalIdentifier: `ccn-interacted`,
        privacy: `${namespace}.cookies.cookieNotice.privacy`,
        responsible: `${namespace}.cookies.cookieNotice.responsible`,
        title: `${namespace}.cookies.cookieNotice.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `ccn-version`,
        duration: `${namespace}.durations.oneYear`,
        explanation: ``,
        internalIdentifier: `ccn-version`,
        privacy: `${namespace}.cookies.cookieNotice.privacy`,
        responsible: `${namespace}.cookies.cookieNotice.responsible`,
        title: `${namespace}.cookies.cookieNotice.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-cart-id`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.cart.explanation`,
        internalIdentifier: `rin-cart-id`,
        privacy: ``,
        responsible: `${namespace}.cookies.cart.responsible`,
        title: `${namespace}.cookies.cart.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-carts-for-checkout`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.cart.explanation`,
        internalIdentifier: `rin-carts-for-checkout`,
        privacy: ``,
        responsible: `${namespace}.cookies.cart.responsible`,
        title: `${namespace}.cookies.cart.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-personal-use-cart-id`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.cart.explanation`,
        internalIdentifier: `rin-personal-use-cart-id`,
        privacy: ``,
        responsible: `${namespace}.cookies.cart.responsible`,
        title: `${namespace}.cookies.cart.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-session`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.session.explanation`,
        internalIdentifier: `rin-session`,
        privacy: ``,
        responsible: `${namespace}.cookies.session.responsible`,
        title: `${namespace}.cookies.session.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-token`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.auth.explanation`,
        internalIdentifier: `rin-token`,
        privacy: ``,
        responsible: `${namespace}.cookies.auth.responsible`,
        title: `${namespace}.cookies.auth.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-language`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.language.explanation`,
        internalIdentifier: `rin-language`,
        privacy: ``,
        responsible: `${namespace}.cookies.language.responsible`,
        title: `${namespace}.cookies.language.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-currency`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.currency.explanation`,
        internalIdentifier: `rin-currency`,
        privacy: ``,
        responsible: `${namespace}.cookies.currency.responsible`,
        title: `${namespace}.cookies.currency.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-delivery-address-iso`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.deliveryAddressIso.explanation`,
        internalIdentifier: `rin-delivery-address-iso`,
        privacy: ``,
        responsible: `${namespace}.cookies.deliveryAddressIso.responsible`,
        title: `${namespace}.cookies.deliveryAddressIso.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-invoice-address-iso`,
        duration: `${namespace}.durations.oneYear`,
        explanation: `${namespace}.cookies.invoiceAddressIso.explanation`,
        internalIdentifier: `rin-invoice-address-iso`,
        privacy: ``,
        responsible: `${namespace}.cookies.invoiceAddressIso.responsible`,
        title: `${namespace}.cookies.invoiceAddressIso.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-bouncer-shopping-starttime`,
        duration: `${namespace}.durations.currentSession`,
        explanation: `${namespace}.cookies.limiting.explanation`,
        internalIdentifier: `rin-bouncer-shopping-starttime`,
        privacy: ``,
        responsible: `${namespace}.cookies.limiting.responsible`,
        title: `${namespace}.cookies.limiting.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-bouncer-sleeping-mode`,
        duration: `${namespace}.durations.currentSession`,
        explanation: `${namespace}.cookies.limiting.explanation`,
        internalIdentifier: `rin-bouncer-sleeping-mode`,
        privacy: ``,
        responsible: `${namespace}.cookies.limiting.responsible`,
        title: `${namespace}.cookies.limiting.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-bouncer-token`,
        duration: `${namespace}.durations.currentSession`,
        explanation: `${namespace}.cookies.limiting.explanation`,
        internalIdentifier: `rin-bouncer-token`,
        privacy: ``,
        responsible: `${namespace}.cookies.limiting.responsible`,
        title: `${namespace}.cookies.limiting.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-bouncer-waiting-token`,
        duration: `${namespace}.durations.currentSession`,
        explanation: `${namespace}.cookies.limiting.explanation`,
        internalIdentifier: `rin-bouncer-waiting-token`,
        privacy: ``,
        responsible: `${namespace}.cookies.limiting.responsible`,
        title: `${namespace}.cookies.limiting.title`,
        value: true,
      },
      {
        active: true,
        cookieNames: `rin-redirect`,
        duration: `${namespace}.durations.currentSession`,
        explanation: `${namespace}.cookies.redirect.explanation`,
        internalIdentifier: `rin-redirect`,
        privacy: ``,
        responsible: `${namespace}.cookies.redirect.responsible`,
        title: `${namespace}.cookies.redirect.title`,
        value: true,
      },
    ],
    explanation: `${namespace}.categories.necessary.explanation`,
    indeterminate: false,
    name: essentialCookiesCategoryName,
    title: `${namespace}.categories.necessary.title`,
  },
  {
    active: false,
    cookies: [
      {
        active: false,
        cookieNames: `rin-version`,
        duration: `${namespace}.durations.oneDay`,
        explanation: `${namespace}.cookies.splittest.explanation`,
        internalIdentifier: `rin-version`,
        privacy: ``,
        responsible: `${namespace}.cookies.splittest.responsible`,
        title: `${namespace}.cookies.splittest.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `__Secure-3PAPISID, APISID, user_credentials, amplitude_id_*, session_token, _fw_crm_v, JSESSIONID, ANID, ajs_group_id, tag_sort_key, NID, __hstc, session_state, HSID, SSID, FC-XSRF-TOKEN, growSumoPartnerKey, helpdesk_url, _hp2_*, _BEAMER_*, zarget_*, fm_sid_*`,
        duration: `${namespace}.durations.threeMonthsToOneYear`,
        explanation: `${namespace}.cookies.freshChat.explanation`,
        internalIdentifier: `freshchat`,
        privacy: `${namespace}.cookies.freshChat.privacy`,
        responsible: `${namespace}.cookies.freshChat.responsible`,
        title: `${namespace}.cookies.freshChat.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `player, sync_active, vimeo_gdpr_optin, __zlcmid, is_logged_in, has_logged_in, sd_client_id, __zlcmid, __ssid, continuous_play_v3, vuid`,
        duration: `${namespace}.durations.oneToTenYears`,
        explanation: `${namespace}.cookies.vimeo.explanation`,
        internalIdentifier: `vimeo `,
        privacy: `${namespace}.cookies.vimeo.privacy`,
        responsible: `${namespace}.cookies.vimeo.responsible`,
        title: `${namespace}.cookies.vimeo.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `PHPSESSID, lufursdcevgps`,
        duration: `${namespace}.durations.twoYears`,
        explanation: ``,
        internalIdentifier: `Prescreen`,
        privacy: `${namespace}.cookies.prescreen.privacy`,
        responsible: `${namespace}.cookies.prescreen.responsible`,
        title: `${namespace}.cookies.prescreen.title`,
        value: true,
      },
    ],
    explanation: `${namespace}.categories.functional.explanation`,
    indeterminate: false,
    name: `functional`,
    title: `${namespace}.categories.functional.title`,
  },
  {
    active: false,
    cookies: [
      {
        active: false,
        cookieNames: `_ga,_gat,_gid,_gat_gtag_UA_<property-id>,AMP_TOKEN,__utma,__utmt,__utmb,__utmc,__utmz,__utmv`,
        duration: `${namespace}.durations.twoYears`,
        explanation: ``,
        internalIdentifier: `ga`,
        privacy: `${namespace}.cookies.googleAnalytics.privacy`,
        responsible: `${namespace}.cookies.googleAnalytics.responsible`,
        title: `${namespace}.cookies.googleAnalytics.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `_gcl_au`,
        duration: `${namespace}.durations.twoYears`,
        explanation: ``,
        internalIdentifier: `_gcl_au`,
        privacy: `${namespace}.cookies.googleTagManager.privacy`,
        responsible: `${namespace}.cookies.googleTagManager.responsible`,
        title: `${namespace}.cookies.googleTagManager.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `sessionstack-session-id-*,sessionstack-active-window-*,sessionstack-identified-*,sessionstack-anonymous-*`,
        duration: `${namespace}.durations.currentSessionToOneYear`,
        explanation: ``,
        internalIdentifier: `sessionstack`,
        privacy: `${namespace}.cookies.sessionStack.privacy`,
        responsible: `${namespace}.cookies.sessionStack.responsible`,
        title: `${namespace}.cookies.sessionStack.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `_fbp,fr,datr,sb`,
        duration: `${namespace}.durations.threeMonthsToOneYear`,
        explanation: ``,
        internalIdentifier: `fb`,
        privacy: `${namespace}.cookies.facebook.privacy`,
        responsible: `${namespace}.cookies.facebook.responsible`,
        title: `${namespace}.cookies.facebook.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `_pk_id, _pk_ref, _pk_ses, _pk_cvar, _pk_hsr`,
        duration: `${namespace}.durations.currentSessionToOneYear`,
        explanation: ``,
        internalIdentifier: `_pk`,
        privacy: `${namespace}.cookies.matomo.privacy`,
        responsible: `${namespace}.cookies.matomo.responsible`,
        title: `${namespace}.cookies.matomo.title`,
        value: true,
      },
      {
        active: false,
        cookieNames: `_derived_epik, _epik, _pin_unauth, _pinterest_ct, _pinterest_ct_rt, _pinterest_ct_ua, _pinterest_sess, _routing_id`,
        duration: `${namespace}.durations.currentSessionToOneYear`,
        explanation: ``,
        internalIdentifier: `pinterest`,
        privacy: `${namespace}.cookies.pinterest.privacy`,
        responsible: `${namespace}.cookies.pinterest.responsible`,
        title: `${namespace}.cookies.pinterest.title`,
        value: true,
      },
    ],
    explanation: `${namespace}.categories.statistics.explanation`,
    indeterminate: false,
    name: `statistic`,
    title: `${namespace}.categories.statistics.title`,
  },
];

export default cookies;
