export default {
  translations: {
    namespace: {
      list: `cookie-notice-list`,
      ufe: `cookie-notice-ufe`,
    },
  },
  version: {
    hash: {
      salt: `6`,
    },
  },
};
