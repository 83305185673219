import cookieNoticeListCs from './cs/cookie-notice-list.json';
import cookieNoticeListDe from './de/cookie-notice-list.json';
import cookieNoticeListEn from './en/cookie-notice-list.json';
import cookieNoticeListEs from './es/cookie-notice-list.json';
import cookieNoticeListFr from './fr/cookie-notice-list.json';
import cookieNoticeListHu from './hu/cookie-notice-list.json';
import cookieNoticeListIt from './it/cookie-notice-list.json';
import cookieNoticeListNl from './nl/cookie-notice-list.json';
import cookieNoticeListPl from './pl/cookie-notice-list.json';
import cookieNoticeListPt from './pt/cookie-notice-list.json';
import cookieNoticeListSk from './sk/cookie-notice-list.json';

import cookieNoticeUfeCs from './cs/cookie-notice-ufe.json';
import cookieNoticeUfeDe from './de/cookie-notice-ufe.json';
import cookieNoticeUfeEn from './en/cookie-notice-ufe.json';
import cookieNoticeUfeEs from './es/cookie-notice-ufe.json';
import cookieNoticeUfeFr from './fr/cookie-notice-ufe.json';
import cookieNoticeUfeHu from './hu/cookie-notice-ufe.json';
import cookieNoticeUfeIt from './it/cookie-notice-ufe.json';
import cookieNoticeUfeNl from './nl/cookie-notice-ufe.json';
import cookieNoticeUfePl from './pl/cookie-notice-ufe.json';
import cookieNoticeUfePt from './pt/cookie-notice-ufe.json';
import cookieNoticeUfeSk from './sk/cookie-notice-ufe.json';

export default {
  cs: {
    'cookie-notice-list': cookieNoticeListCs,
    'cookie-notice-ufe': cookieNoticeUfeCs,
  },
  de: {
    'cookie-notice-list': cookieNoticeListDe,
    'cookie-notice-ufe': cookieNoticeUfeDe,
  },
  'en-US': {
    'cookie-notice-list': cookieNoticeListEn,
    'cookie-notice-ufe': cookieNoticeUfeEn,
  },
  es: {
    'cookie-notice-list': cookieNoticeListEs,
    'cookie-notice-ufe': cookieNoticeUfeEs,
  },
  fr: {
    'cookie-notice-list': cookieNoticeListFr,
    'cookie-notice-ufe': cookieNoticeUfeFr,
  },
  hu: {
    'cookie-notice-list': cookieNoticeListHu,
    'cookie-notice-ufe': cookieNoticeUfeHu,
  },
  it: {
    'cookie-notice-list': cookieNoticeListIt,
    'cookie-notice-ufe': cookieNoticeUfeIt,
  },
  nl: {
    'cookie-notice-list': cookieNoticeListNl,
    'cookie-notice-ufe': cookieNoticeUfeNl,
  },
  pl: {
    'cookie-notice-list': cookieNoticeListPl,
    'cookie-notice-ufe': cookieNoticeUfePl,
  },
  pt: {
    'cookie-notice-list': cookieNoticeListPt,
    'cookie-notice-ufe': cookieNoticeUfePt,
  },
  sk: {
    'cookie-notice-list': cookieNoticeListSk,
    'cookie-notice-ufe': cookieNoticeUfeSk,
  },
};
