// import cookies, { essentialCookiesCategoryName } from '../../configs/cookie-list';
import AppHelper from './app-helper';

export default class CookieHelper {
  public static getCcnInteracted(): string[] | boolean {
    const item = this.getCookie(`ccn-interacted`);
    if(typeof item === `string`) {
      return JSON.parse(item);
    }
    return item;
  }

  public static getCcnVersion(): string | false {
    return this.getCookie(`ccn-version`);
  }

  public static checkVersion(): boolean {
    const versionCookie = this.getCcnVersion();
    if(!versionCookie || versionCookie !== AppHelper.createVersionHash()) {
      this.removeNonEssentialCookies();
      this.removeCcnInteracted();
      return false;
    }
    return true;
  }

  public static removeCcnInteracted(): void {
    this.removeCookie(`ccn-interacted`);
    this.removeCcnVersion();
    localStorage.clear();
  }

  public static removeCcnVersion(): void {
    this.removeCookie(`ccn-version`);
  }

  public static setCcnInteracted(acceptedCookies: string[]): void {
    this.setCookie(`ccn-interacted`, JSON.stringify(acceptedCookies));
    this.setCookie(`ccn-version`, AppHelper.createVersionHash());
  }

  public static removeNonEssentialCookies(): void {
    // const essentialCookieCategory = cookies.find(el => el.name === essentialCookiesCategoryName);
    // let essentialCookieNames: string[] = [];
    // if(essentialCookieCategory) {
    //   essentialCookieNames = essentialCookieCategory.cookies.map(cookie => cookie.internalIdentifier);
    // }
    const allCookieNames = this.getAllCookieNames();
    for(const cookieName of allCookieNames) {
      // if(!essentialCookieNames.includes(cookieName)) {
      this.removeCookie(cookieName);
      // }
    }
  }

  private static getCookie(name: string): string | false {
    const cookiesArray = document.cookie.split(`; `);
    const cookieArray = cookiesArray.filter(item => {
      const cookieKey = item.split(`=`)[0];
      return cookieKey === name;
    });
    if(cookieArray.length === 1) {
      return decodeURIComponent(cookieArray[0].split(`=`)[1]);
    }
    if(cookieArray.length > 1) {
      console.warn(`Multiple cookies with name ${name} found! This might be unintended, please check implementation.`);
    }
    return false;
  }

  private static removeCookie(name: string): void {
    const domain = window.location.host.search(/localhost/i) === 0 ? `localhost` : window.location.host.replace(/^(.*?)\.(?!(\..*\.com))/, ``);
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain};Secure;SameSite=lax`;
  }

  // eslint-disable-next-line quotes
  private static setCookie(name: string, value: boolean | number | string, options?: { expires?: number; 'max-age'?: number }): void {
    const domain = window.location.host.search(/localhost/i) === 0 ? `localhost` : window.location.host.replace(/^(.*?)\.(?!(\..*\.com))/, ``);
    let cookie = `${name}=${encodeURIComponent(value)};path=/;domain=${domain};`;

    if(options) {
      // eslint-disable-next-line no-restricted-syntax
      for(const option in options) {
        if(Object.prototype.hasOwnProperty.call(options, option)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          cookie += `${option}=${options[option]}`;
        }
      }
    }

    // eslint-disable-next-line quotes
    if(!options || (options && !options.expires && !options['max-age'])) {
      cookie += `max-age=${60 * 60 * 24 * 365};expires=${new Date(Date.now() + (60 * 60 * 24 * 365 * 1000)).toUTCString()};`;
    }

    cookie += window.location.protocol !== `http:` ? `Secure;` : ``;
    cookie += `SameSite=Lax`;

    document.cookie = cookie;
  }

  private static getAllCookieNames(): string[] {
    const cookiesArray = document.cookie.split(`; `);
    const cookieArray = cookiesArray.filter(item => {
      return item.split(`=`)[0];
    });
    return cookieArray.map(cookie => decodeURIComponent((cookie.split(`=`)[0])));
  }
}
