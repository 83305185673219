import { Component, createApp } from 'vue';
import { ELanguage } from '@ringana/configs';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import config from '../configs/config';
import messages from './i18n';

const app = createApp(App as unknown as Component);

// eslint-disable-next-line quotes
declare module '@vue/runtime-core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface ComponentCustomProperties {
    $translationsListNamespace: string;
    $translationsUfeNamespace: string;
  }
}

/*
 * split on - to only get the Primary Language Subtag (https://datatracker.ietf.org/doc/html/rfc5646#section-2.2.1)
 * if the Primary Language Subtag is not in our language list we automatically fall back to en-US
 */
const languageValue = window.navigator?.language.split(`-`)[0] || ELanguage.EN;
const i18n = createI18n({
  fallbackLocale: `en-US`,
  locale: languageValue === ELanguage.EN ? `en-US` : languageValue,
  messages,
});

const namespacesWithDot = {
  list: `${config.translations.namespace.list}.`,
  ufe: `${config.translations.namespace.ufe}.`,
};

// Set i18n instance on app
app.use(i18n);

app.provide(`translationsListNamespace`, namespacesWithDot.list);
app.config.globalProperties.$translationsListNamespace = namespacesWithDot.list;
app.provide(`translationsUfeNamespace`, namespacesWithDot.ufe);
app.config.globalProperties.$translationsUfeNamespace = namespacesWithDot.ufe;

app.mount(`#ccn`);

export {
  i18n,
  namespacesWithDot,
};
