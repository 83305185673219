<template>
  <div class="chax-ccn">
    <CookieNotice />
  </div>
</template>

<script lang="ts" setup>
  import { onMounted } from 'vue';
  import CookieNotice from './components/CookieNotice.vue';

  onMounted(() => {
    if(typeof window.CustomEvent === `function`) return;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function CustomEvent(event: string, params: { bubbles: boolean; cancelable: boolean; detail: unknown }) {
      // eslint-disable-next-line no-param-reassign
      params = params || { bubbles: false, cancelable: false, detail: null };
      // eslint-disable-next-line no-var,vars-on-top
      var evt = document.createEvent(`CustomEvent`);
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CustomEvent = CustomEvent;
  });
</script>

<style lang="scss">
  @import 'src/assets/styles/overrides';
  @import 'src/assets/styles/css-variables';

  .ccn-overflow-hidden {
    overflow: hidden !important;
  }

  .chax-ccn {
    font-family: 'Eina01-Regular', sans-serif;
  }

  .text-center {
    text-align: center;
  }

  .width-100 {
    width: 100%;
  }
</style>
