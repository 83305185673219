<template>
  <div
    class="ccn-headline-with-checkbox"
    :class="[
      { 'ccn-headline-with-checkbox--disabled': disabled },
      { 'ccn-headline-with-checkbox--no-spacing': noSpacing },
    ]">
    <CustomHeadline
      :clickable="clickable"
      no-spacing
      :size="size"
      :text="text"
      @click="updateActive" />
    <CustomToggle
      :active="active"
      :disabled="disabled"
      :indeterminate="indeterminate"
      :size="toggleSize"
      @update:active="$emit(`update:active`, $event)" />
  </div>
</template>

<script lang="ts" setup>
  import { ETextSize, EToggleSize } from '../../common/enums/index.enum';
  import CustomToggle from './CustomToggle.vue';
  import CustomHeadline from './CustomHeadline.vue';

  const emit = defineEmits<{
    (e: `update:active`, value: boolean): void;
  }>();
  const props = withDefaults(defineProps<{
    active?: boolean;
    border?: boolean;
    clickable?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
    noSpacing?: boolean;
    size?: ETextSize;
    text: string;
    toggleSize?: EToggleSize;
  }>(), {
    active: false,
    border: false,
    clickable: false,
    disabled: false,
    indeterminate: false,
    noSpacing: false,
    size: ETextSize.LG,
    toggleSize: EToggleSize.MD,
  });

  function updateActive(): void {
    if(props.disabled) {
      return;
    }
    emit(`update:active`, !props.active);
  }
</script>

<style lang="scss" scoped>
  .ccn-headline-with-checkbox {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .ccn-headline-with-checkbox--disabled {
    cursor: not-allowed;
  }

  .ccn-headline-with-checkbox--no-spacing {
    margin-bottom: 0;
  }
</style>
