<template>
  <div
    v-if="showCookieNotice"
    class="new-cookie-notice">
    <div class="new-cookie-notice__container">
      <div class="new-cookie-notice__text-container">
        <div class="new-cookie-notice__headline-container">
          <FlowerLogo class="new-cookie-notice__flower-logo" />
          <CustomHeadline
            v-if="$t(`${$translationsUfeNamespace}notice.headline`)"
            class="new-cookie-notice__headline"
            icon-path="icons/flower.svg"
            :text="$t(`${$translationsUfeNamespace}notice.headline`)"
            uppercase />
        </div>
        <div class="new-cookie-notice__text">
          {{ $t(`${$translationsUfeNamespace}notice.text`) }}
        </div>
      </div>
      <PreferencesSection
        class="new-cookie-notice__preferences"
        :cookies="cookies"
        visible
        @update:active-single-cookie="updateSingleCookieActive"
        @update:category-active="updateCategoryActive" />
      <PreferencesSectionFooter
        class="new-cookie-notice__footer"
        @click:ccn-accept-all="acceptAll"
        @click:ccn-accept-selection="acceptSelection" />
    </div>
  </div>
  <JobsCookieInfo
    v-else-if="showJobsCookieInfo && !showCookieNotice"
    @update:cookies="updateCookiesForJobs" />
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted, ref } from 'vue';
  import cookieList from '../../configs/cookie-list';
  import { ICookie } from '../common/interfaces/index.interface';
  import CookieHelper from '../helpers/cookie-helper';
  import DomEventHelper from '../helpers/dom-event-helper';
  import PreferencesSection from './subcomponents/PreferencesSection.vue';
  import PreferencesSectionFooter from './subcomponents/PreferencesSectionFooter.vue';
  import CustomHeadline from './subcomponents/CustomHeadline.vue';
  import FlowerLogo from './subcomponents/FlowerLogo.vue';
  import JobsCookieInfo from './subcomponents/JobsCookieInfo.vue';

  const acceptedCookies = ref<string[]>([]);
  const cookies = ref<ICookie[]>(JSON.parse(JSON.stringify(cookieList)));
  const showCookieNotice = ref(true);
  const showJobsCookieInfo = ref(false);

  function setAllOptionalCookies(): void {
    showJobsCookieInfo.value = false;

    const optionalCookies = cookies.value.filter(cookie => cookie.name !== `necessary`);
    if(optionalCookies.length > 0) {
      for(const object of optionalCookies) {
        for(const cookie of object.cookies) {
          DomEventHelper.fireCookieSetEvent({ name: cookie.internalIdentifier });
          acceptedCookies.value.push(cookie.internalIdentifier);
        }
      }
    }
  }

  function setSelectedOptionalCookies(): void {
    const optionalCookies = cookies.value.filter(cookie => cookie.name !== `necessary`);
    if(optionalCookies.length > 0) {
      for(const object of optionalCookies) {
        for(const cookie of object.cookies) {
          if(cookie.active) {
            if(cookie.internalIdentifier === `Prescreen`) {
              showJobsCookieInfo.value = false;
            }

            DomEventHelper.fireCookieSetEvent({ name: cookie.internalIdentifier });
            acceptedCookies.value.push(cookie.internalIdentifier);
          }
        }
      }
    }
  }

  function setNecessaryCookies(): void {
    const necessaryCookies = cookies.value.find(cookie => cookie.name === `necessary`);
    if(necessaryCookies) {
      for(const cookie of necessaryCookies.cookies) {
        if(cookie.internalIdentifier === `ccn-interacted`) {
          // no need to fire an event for a cookie the notice itself sets
        } else {
          DomEventHelper.fireCookieSetEvent({ name: cookie.internalIdentifier });
          acceptedCookies.value.push(cookie.internalIdentifier);
        }
      }
    }
  }

  function hideCookieNotice(): void {
    showCookieNotice.value = false;
    document.body.classList.remove(`ccn-overflow-hidden`);
  }

  function acceptAll(): void {
    setNecessaryCookies();
    setAllOptionalCookies();
    CookieHelper.setCcnInteracted(acceptedCookies.value);
    hideCookieNotice();
  }

  function acceptSelection(): void {
    setNecessaryCookies();
    setSelectedOptionalCookies();
    CookieHelper.setCcnInteracted(acceptedCookies.value);
    hideCookieNotice();
  }

  function checkFunctionalCookies(): void {
    showJobsCookieInfo.value = true;
  }

  function updateAllCookiesOfCategory(active: boolean, indexOfCategory: number): void {
    for(const cookie of cookies.value[indexOfCategory].cookies) {
      cookie.active = active;
    }
  }

  function updateCategory(category: string): void {
    const cookieTypeIndex = cookies.value.findIndex(cookie => cookie.name === category);
    if(cookieTypeIndex > -1) {
      const activeCookies = cookies.value[cookieTypeIndex].cookies.filter(cookie => cookie.active);
      if(activeCookies.length > 0) {
        if(activeCookies.length === cookies.value[cookieTypeIndex].cookies.length) {
          cookies.value[cookieTypeIndex].active = true;
          cookies.value[cookieTypeIndex].indeterminate = false;
        } else {
          cookies.value[cookieTypeIndex].active = false;
          cookies.value[cookieTypeIndex].indeterminate = true;
        }
      } else {
        cookies.value[cookieTypeIndex].active = false;
        cookies.value[cookieTypeIndex].indeterminate = false;
      }
    }
  }

  function updateCategoryActive(event: { active: boolean; cookies: ICookie }): void {
    const eventToModify = event;
    eventToModify.cookies.active = event.active;
    eventToModify.cookies.indeterminate = false;
    const cookieTypeIndex = cookies.value.findIndex(cookie => cookie.name === eventToModify.cookies.name);
    cookies.value.splice(cookieTypeIndex, 1, eventToModify.cookies);
    updateAllCookiesOfCategory(eventToModify.active, cookieTypeIndex);
  }

  function updateCookiesForJobs(): void {
    CookieHelper.removeNonEssentialCookies();
    showCookieNotice.value = true;
  }

  function updateSingleCookieActive(event: { active: boolean; cookieCategory: string; cookieName: string }): void {
    const cookieTypeIndex = cookies.value.findIndex(cookie => cookie.name === event.cookieCategory);
    const cookieObject = cookies.value[cookieTypeIndex].cookies.find(cookie => cookie.internalIdentifier === event.cookieName);
    const cookieObjectIndex = cookies.value[cookieTypeIndex].cookies.findIndex(cookie => cookie.internalIdentifier === event.cookieName);
    if(cookieObject && cookieObjectIndex > -1) {
      cookieObject.active = event.active;
      cookies.value[cookieTypeIndex].cookies.splice(cookieObjectIndex, 1, cookieObject);
    }
    updateCategory(event.cookieCategory);
  }

  onMounted(() => {
    if(CookieHelper.getCcnInteracted() && CookieHelper.checkVersion()) {
      hideCookieNotice();
    } else {
      document.body.classList.add(`ccn-overflow-hidden`);
    }

    document.addEventListener(`rin-cookie-settings-info`, checkFunctionalCookies);
  });

  onUnmounted(() => {
    document.removeEventListener(`rin-cookie-settings-info`, checkFunctionalCookies);
  });
</script>

<style lang="scss" scoped>
  @import 'src/assets/styles/breakpoints';

  .new-cookie-notice {
    background-color: var(--ccn-bg-color-opacity-40);
    height: 100vh;
    left: 0;
    margin: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 99999;

    &__container {
      background: var(--secondary-color);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 510px;
      left: 50%;
      max-width: 720px;
      padding: 20px 15px;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;

      @include breakpoint-md-min {
        height: 730px;
        padding: 40px;
        width: auto;
      }
    }

    &__preferences {
      overflow-y: auto;

      @include breakpoint-md-min {
        height: 100%;
      }
    }

    &__flower-logo {
      height: 65px;
      margin-right: 20px;
      max-height: 65px;

      @include breakpoint-md {
        display: none;
      }
    }

    &__headline {
      color: var(--ccn-text-color);
      margin: 0;
      padding: 0;

      @include breakpoint-md {
        font-size: 14px !important;
        margin-bottom: 10px;
      }
    }

    &__text {
      border: 1px solid var(--color-gray-light);
      box-sizing: border-box;
      font-size: 12px;
      height: 110px;
      letter-spacing: .5px;
      line-height: 1.5;
      margin: 0 0 10px 0;
      overflow-y: scroll;
      padding: 10px;

      @include breakpoint-md-min {
        border: none;
        flex: 1 1 auto;
        font-size: 13px;
        height: auto;
        margin: 10px 5px 10px 0;
        overflow-y: auto;
        padding: 0;
      }
    }

    &__headline-container {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
    }

    &__text-container {
      display: flex;
      flex-direction: column;
      height: auto;

      @include breakpoint-md-min {
        margin-bottom: 10px;
      }
    }
  }
</style>
