<template>
  <div
    class="ccn-cookie-category"
    :class="{ 'ccn-cookie-category--disabled': disabled }">
    <div class="ccn-cookie-category__headline-container">
      <CustomHeadline
        class="ccn-cookie-category__headline"
        :size="ETextSize.SM"
        :text="$t(headline)" />
      <span
        class="ccn-cookie-category__show-more"
        @click="showCookieList = !showCookieList">
        {{ showCookieList ? $t(`${$translationsUfeNamespace}preferences.less`) : $t(`${$translationsUfeNamespace}preferences.moreDetails`) }}
        <ArrowIcon
          class="ccn-cookie-category__arrow"
          :class="{ 'ccn-cookie-category__arrow--inverted' : showCookieList }" />
      </span>
      <CustomToggle
        :active="active"
        class="ccn-cookie-category__toggle-mobile"
        :disabled="disabled"
        :indeterminate="indeterminate"
        :size="EToggleSize.SM"
        @update:active="$emit(`update:active`, $event)" />
    </div>
    <ExplanationParagraph
      v-if="explanation"
      :active="active"
      class="ccn-cookie-category__explanation"
      :disabled="disabled"
      :indeterminate="indeterminate"
      :size="ETextSize.SM"
      :text="$t(explanation)"
      toggle
      :toggle-size="EToggleSize.SM"
      @update:active="$emit(`update:active`, $event)" />
    <template v-if="showCookieList">
      <CookieList
        class="ccn-cookie-category__cookie-list"
        :disabled="disabled"
        :entries="cookies"
        @update:active-single-cookie="$emit(`update:active-single-cookie`, { ...$event, cookieCategory: name })" />
    </template>
    <span
      class="ccn-cookie-category__show-more-mobile"
      @click="showCookieList = !showCookieList">
      {{ showCookieList ? $t(`${$translationsUfeNamespace}preferences.less`) : $t(`${$translationsUfeNamespace}preferences.moreDetails`) }}
      <ArrowIcon
        class="ccn-cookie-category__arrow"
        :class="{ 'ccn-cookie-category__arrow--inverted' : showCookieList }" />
    </span>
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { ICookieListEntry } from '../../common/interfaces/index.interface';
  import { ETextSize, EToggleSize } from '../../common/enums/index.enum';
  import CustomHeadline from './CustomHeadline.vue';
  import ArrowIcon from './ArrowIcon.vue';
  import CustomToggle from './CustomToggle.vue';
  import ExplanationParagraph from './ExplanationParagraph.vue';
  import CookieList from './CookieList.vue';

  withDefaults(defineProps<{
    active?: boolean;
    cookies: ICookieListEntry[];
    disabled?: boolean;
    explanation?: string;
    headline: string;
    indeterminate?: boolean;
    name: string;
  }>(), {
    active: false,
    disabled: false,
    explanation: ``,
    indeterminate: false,
  });

  const showCookieList = ref(false);
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/breakpoints';

  .ccn-cookie-category {
    margin: 10px 0 15px 0;

    @include breakpoint-md-min {
      margin: 0 0 10px 0;
    }

    &__headline-container {
      align-items: center;
      border-bottom: 1px solid var(--primary-color);
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 5px;
    }

    &__headline {
      font-family: 'Eina01-Regular', sans-serif;
      margin: 0 0 5px 0;
      padding-bottom: 0;
    }

    &__toggle-mobile {
      @include breakpoint-md-min {
        display: none;
      }
    }

    &__explanation {
      color: var(--ccn-category-explanation-text-color);
      font-size: 14px;
      margin: 0 5px 10px 5px;

      @include breakpoint-md {
        display: none;
      }
    }

    &__cookie-list {
      margin: 0 10px;
    }

    &__show-more {
      cursor: pointer;
      font-size: 12px;

      @include breakpoint-md {
        display: none;
      }
    }

    &__show-more-mobile {
      cursor: pointer;
      display: flex;
      font-size: 12px;
      justify-content: center;

      @include breakpoint-md-min {
        display: none;
      }
    }

    &__arrow {
      height: 10px;
      margin-left: 5px;
      transform: rotate(90deg) translateX(20%);
      width: 10px;
    }

    &__arrow--inverted {
      transform: rotate(270deg);
    }
  }

  .ccn-cookie-category--disabled {
    cursor: not-allowed;
    opacity: .5;
  }
</style>
