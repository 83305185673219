export default class DomEventHelper {
  public static fireEvent(options: { detail?: unknown; domElement?: Document | HTMLElement | Window; name: string }): void {
    const element = options.domElement || document;
    const event = this.createEvent(options.name, options.detail);
    element.dispatchEvent(event);
  }

  public static fireCookieSetEvent(options: { detail?: unknown; domElement?: Document | HTMLElement | Window; name: string }): void {
    this.fireEvent({
      ...options,
      name: `ccn-set-${options.name}`,
    });
  }

  private static createEvent(name: string, detail: unknown): Event {
    let event;

    if((window).CustomEvent) {
      event = new CustomEvent(name, {
        bubbles: true,
        detail,
      });
    } else {
      event = document.createEvent(`CustomEvent`);
      event.initCustomEvent(name, true, true, {
        detail,
      });
    }
    return event;
  }
}
