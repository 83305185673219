export enum ETextSize {
  XS = `xs`,
  SM = `sm`,
  MD = `md`,
  LG = `lg`,
  XL = `xl`,
}

export enum EToggleSize {
  MD = `md`,
  SM = `sm`,
  XS = `xs`,
}
