<template>
  <div
    class="custom-toggle pointer"
    :class="[
      `custom-toggle--${size}`,
      { 'custom-toggle--disabled': disabled },
    ]"
    @click="!disabled ? $emit(`update:active`, !active) : undefined">
    <span
      v-if="labelBefore"
      class="custom-toggle__label custom-toggle__label-before">
      {{ labelBefore }}
    </span>
    <input
      ref="customToggleInputCheckboxRef"
      :checked="active"
      :disabled="disabled"
      hidden
      type="checkbox" />
    <div class="custom-toggle__toggle"></div>
    <span
      v-if="labelAfter"
      class="custom-toggle__label custom-toggle__label-after">
      {{ labelAfter }}
    </span>
  </div>
</template>

<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { EToggleSize } from '../../common/enums/index.enum';

  const props = defineProps({
    active: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    indeterminate: {
      default: false,
      type: Boolean,
    },
    labelAfter: {
      default: null,
      type: String,
    },
    labelBefore: {
      default: null,
      type: String,
    },
    size: {
      default: EToggleSize.MD,
      type: String as () => EToggleSize,
      validator: val => Object.values(EToggleSize).includes(val as EToggleSize),
    },
  });

  const customToggleInputCheckboxRef = ref<HTMLInputElement | null>(null);

  watch(() => props.indeterminate, () => {
    if(customToggleInputCheckboxRef.value) {
      customToggleInputCheckboxRef.value.indeterminate = props.indeterminate;
    }
  }, {
    immediate: true,
  });
</script>

<style lang="scss" scoped>
  .custom-toggle {
    --ccn-toggle-bg-color: #e5e5e5;
    --ccn-toggle-bg-color-active: #000;
    --ccn-toggle-bg-color-indeterminate: #fdd43f;
    --ccn-toggle-after-color: #fff;
    --ccn-toggle-after-color-active: #fff;

    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    &__label {
      display: inline-block;
      user-select: none;
    }

    &__label-after {
      margin-left: 10px;
    }

    &__label-before {
      margin-right: 10px;
    }

    &__toggle {
      background-color: var(--ccn-toggle-bg-color);
      border-radius: 15px;
      display: inline-block;
      position: relative;
      transition: all;
      vertical-align: middle;

      &::after {
        background-color: var(--ccn-toggle-after-color);
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all 250ms;
      }
    }
  }

  .custom-toggle--disabled {
    cursor: not-allowed;
  }

  .custom-toggle--xs {
    $parent: '.custom-toggle';

    #{$parent}__toggle {
      height: 16px;
      width: 30px;

      &::after {
        height: 12px;
        right: 16px;
        width: 12px;
      }
    }
  }

  .custom-toggle--sm {
    $parent: '.custom-toggle';

    #{$parent}__toggle {
      height: 22px;
      width: 40px;

      &::after {
        height: 18px;
        right: 20px;
        width: 18px;
      }
    }
  }

  .custom-toggle--md {
    $parent: '.custom-toggle';

    #{$parent}__toggle {
      height: 30px;
      width: 52px;

      &::after {
        height: 22px;
        right: 26px;
        width: 22px;
      }
    }
  }

  input:checked + .custom-toggle__toggle {
    background-color: var(--ccn-toggle-bg-color-active);
  }

  input:checked + .custom-toggle__toggle::after,
  input:indeterminate + .custom-toggle__toggle::after {
    background-color: var(--ccn-toggle-after-color-active);
  }

  .custom-toggle--xs input:checked + .custom-toggle__toggle::after {
    right: 2px;
  }

  .custom-toggle--sm input:checked + .custom-toggle__toggle::after {
    right: 3px;
  }

  .custom-toggle--md input:checked + .custom-toggle__toggle::after {
    right: 4px;
  }

  input:indeterminate + .custom-toggle__toggle {
    background-color: var(--ccn-toggle-bg-color-indeterminate);
  }

  .custom-toggle--xs input:indeterminate + .custom-toggle__toggle::after {
    right: 50%;
    transform: translate(50%, -50%);
  }

  .custom-toggle--sm input:indeterminate + .custom-toggle__toggle::after {
    right: 50%;
    transform: translate(50%, -50%);
  }

  .custom-toggle--md input:indeterminate + .custom-toggle__toggle::after {
    right: 50%;
    transform: translate(50%, -50%);
  }
</style>
