<template>
  <svg
    :fill="color"
    fill-rule="evenodd"
    viewBox="0 0 172.26 403.77"
    xmlns="http://www.w3.org/2000/svg">
    <g
      id="Ebene_3"
      data-name="Ebene 3">
      <path
        v-for="(path, index) of paths"
        :key="`flower-path-${index}`"
        class="cls-1"
        :d="path" />
    </g>
  </svg>
</template>

<script lang="ts" setup>
  withDefaults(defineProps<{
    color?: string;
  }>(), {
    color: `#1d1d1b`,
  });

  const paths = [
    `M35.78,45.33a32.82,32.82,0,0,0,2.61,13.41,46.49,46.49,0,0,0,14,18.78c2.53,2,5.73,3.85,8.83,3a9.72,9.72,0,0,0,2.71-1.41c4.54-3.09,8.48-7.51,9.58-12.88s-.65-10.64-2.36-15.72c-2.55-7.56-5.21-15.35-10.48-21.33C58.73,27,56.32,25,53.47,24.82c-8.53-.57-16.69,11.36-17.55,18.67C35.85,44.1,35.81,44.72,35.78,45.33Z`,
    `M25.44,136.19c3.89,3.05,13.94-2.77,17.9-5.55,5.07-3.57,10.74-11,16.16-12.42-3.14.84-3.8,20.24-2,25.2,4.64,13,11.73,15.25,18.88,14.53,3.37-.33,5.73-2.77,7.23-5.32,3.88-6.6,9.79-26.68,5.33-48.24-.79-3.83-2.79-9.25-5.31-10.06-1.94-.63-5.3-.94-11.39,4.12-.74.62-2.6,1.84-2.53,1.41.5-3.41-3.88-8.23-4.18-8.51a17.06,17.06,0,0,0-8.29-4.64,18,18,0,0,0-5.61-.44C39,87.53,32.43,96.76,25.75,106.49,14,123.53,23.45,134.63,25.44,136.19Z`,
    `M3.16,230.63a7,7,0,0,0,2,5.38c3.64,4.13,13.35,11.27,23.44,13.08a20.8,20.8,0,0,0,8.78-.22c12.63-3.09,35-11.41,43.8-25,1.25-1.92.79-2.54-.67-3.5C76.7,217.88,46,204,39.35,202.77c-6-1.14-16.35,6.85-19.89,9.25C18.24,212.84,3.91,222.34,3.16,230.63Z`,
    `M.72,332.78c1.26,1.67,8.5,7.28,12.15,8.72,2.86,1.13,6.07.15,8.85-1.14,23.13-10.72,39.27-32.49,52-54.58,1.34-2.33,3.68-6.83.79-8-10.6-4.48-45.06,4.39-64.08,27.94C4.3,313.25-2.2,328.91.72,332.78Z`,
    `M76.25,46.12a166.74,166.74,0,0,0-2,17c-.18,2.65-.24,5.5,1.17,7.75,2.43,3.86,8.33,4.1,12.22,1.7S93.85,66,96,61.9c6.42-12.41,12.45-26.3,9.44-39.95C104,15.51,100.82,9.57,97.33,4,92.83-3.22,90.74.38,88,6.6A168.49,168.49,0,0,0,76.25,46.12Z`,
    `M91.71,94.63c2.84,3.52,6,7.32,10.43,8.16,4.85.92,9.61-1.85,13.42-5A58.2,58.2,0,0,0,133,73.36a22.86,22.86,0,0,0,1.8-9.34,11.27,11.27,0,0,0-4.27-8.19c-6.14-4.76-27.93,4.83-35.17,9.24C83,72.58,79.5,79.49,91.71,94.63Z`,
    `M136.16,146c5.84-11.15,23.75-34.24,31.5-32.62,2,.43,2.62,3.1,2.94,5.16,3,19.19,2.18,39.21-4.6,57.4-9.46,25.41-42,56-42,56-3.88,3.88-6.88,2.92-8.44-3.1-1.82-7-1.12-16.71-.13-21.64C121,179.12,130.69,156.47,136.16,146Z`,
    `M89.15,173.46c.76,7.57,6.1,26,7,40.78,1.2,20.14-.35,40.37-3.44,60.28-2.73,17.57-6.54,34.85-10.25,52.33-2.78,13.1-10.61,44.27-20.36,67.38-5.1,12.1,12.5,15.14,23.47-5.51,7.92-14.92,12.09-31.45,15.26-48,3.24-16.88,6.59-33.75,8.77-50.8,1.12-8.7,1.93-17.43,2.26-26.2.72-19.78-2-37.53-7.84-56.23C102.16,201.62,93.16,176.53,89.15,173.46Z`,
  ];
</script>
